
import { _authorizedRequest, _authorizedDownloadRequest, _request, _authorizedRequestWithHeader } from '../http'

export async function getModel(platform, model) {
    return _authorizedRequest("GET", `/Mapping/${model}/${platform}`, null)
}

export async function getConfig(platform, model) {
    return _authorizedRequest("GET", `/DefaultConfig/${platform}/${model}`, null)
}

export async function saveConfig(data) {
    return _authorizedRequest("PUT", `/DefaultConfig`, data)
}


export async function settingQuickbook(data) {
    return _authorizedRequest("POST", `/QuickBooks/config`, data)
}

export async function quickBooksItems(search) {
    return _authorizedRequest("GET", `/QuickBooks/product?search=${search ? search : ""}`, null)
}

export async function quickBooksCustomers(search) {
    return _authorizedRequest("GET", `/QuickBooks/customer?search=${search ? search : ""}`, null)
}
export async function quickBooksOrders(search) {
    return _authorizedRequest("GET", `/QuickBooks/order?search=${search ? search : ""}`, null)
}

export async function quickBooksCompanyInfo() {
    return _authorizedRequest("GET", `/QuickBooks/company`, null)
}

export async function settingFora(data) {
    return _authorizedRequest("POST", `/Fora/config`, data)
}

export async function getSettingFora() {
    return _authorizedRequest("GET", `/Fora`, null)
}

export async function getForaOrderDetail(search) {
    return _authorizedRequest("GET", `/Fora/orderdetail?search=${search ? search : ""}`, null)
}


export async function foraOrders() {
    return _authorizedRequest("GET", `/Fora/order`, null)
}

export async function foraOrdersOrQuotes(orderType, date) {
    return _authorizedRequest("GET", `/Fora/orders?orderType=${orderType}&date=${date}`, null)
}



export async function getCustomConfig(platform, model) {
    return _authorizedRequest("GET", `/CustomConfig/${platform}/${model}`, null)
}

export async function saveCustomConfig(data) {
    return _authorizedRequest("PUT", `/CustomConfig`, data)
}

export async function runJobCustomer(data) {
    return _authorizedRequest("POST", `/RunJob/customer`, data)
}

export async function runJob(entity, cron) {
    return _authorizedRequest("POST", `/RunJob/${entity}/${cron}`, null)
}

export async function deleteJob(jobId) {
    return _authorizedRequest("DELETE", `/RunJob/${jobId}`, null)
}


export async function runJobState() {
    return _authorizedRequest("GET", `/RunJob/state`, null)
}

export async function logs(data) {
    return _authorizedRequest("GET", `/Result?` + jsonToQueryString(data), null)
}

function jsonToQueryString(json) {
    return Object.keys(json)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
        .join('&');
}

export async function sync(data) {
    return _authorizedRequest("POST", `/RunJob/quickbooks_online`, data)
}

export async function syncQBDCustomer(header, customers) {
    return _authorizedRequestWithHeader("POST", `/RunJob/quickbooks_desktop/customers`, customers, header)
}

export async function syncQBDProduct(header, products) {
    return _authorizedRequestWithHeader("POST", `/RunJob/quickbooks_desktop/products`, products, header)
}

export async function disconnectQuickBooks() {
    return _authorizedRequestWithHeader("DELETE", `/QuickBooks/authorize`, null)
}