import React, { useState, useMemo, useEffect } from "react"
import TopNavbar from "../../shares/TopNavbar"
import * as api from '../../services/api'
import * as Account from '../../account';

export default function Callback(props) {
    useEffect(() => {
        Account.Logout()
        window.location.href = "/"
    }, [])

    return (<></>)
} 