export function sortOnKeys(dict) {

    var sorted = [];
    for (var key in dict) {
        sorted[sorted.length] = key;
    }
    sorted.sort();

    var tempDict = {};
    for (var i = 0; i < sorted.length; i++) {
        tempDict[sorted[i]] = dict[sorted[i]];
    }

    return tempDict;
}

export function greeting() {
    var currentTime = new Date();
    currentTime.getHours();
    var currentHour = currentTime.getHours();
    switch (true) {
        case (currentHour < 12):
            return "Good morning! ";
            break;
        case (currentHour > 12 && currentHour < 17):
            return "Good afternoon! "
            break;
        default:
            return "Good evening! ";
            break;
    }
}

export function locale(key) {
    var dict = {
        "quickbooks_online": "QuickBooks Online",
        "quickbooks_desktop": "QuickBooks Desktop",
        "order": "Order",
        "customer": "Customer",
        "product": "Product",
        "JobService.DoWorkCustomer" : "Customer",
        "JobService.DoWorkProduct" : "Product",
        "JobService.DoWorkOrder" : "Order",
        "orders" : "Order",
        "quotes" : "Quote",
        "order" : "Order",
        "quote" : "Quote"
    }

    return dict[key]
}