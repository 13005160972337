


import Callback from "./views/Callback";
import DefaultConfig from "./views/DefaultConfig";
import Home from "./views/Home";
import Signup from "./views/Signup";
import Signin from "./views/Signin";
import Signout from "./views/Signout";
import Mapping from "./views/Mapping";
import Job from "./views/Jobs";
import JobDesktop from "./views/JobsDesktop";
import Logs from "./views/Logs";
import TestAccount from "./views/TestAccount";
import QBD from "./views/QBD";
import Disconnect from "./views/Disconnect";
const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/callback',
    element: <Callback />
  },
  {
    path: '/test',
    element: <TestAccount />
  },
  {
    path: '/signin',
    element: <Signin />
  },
  {
    path: '/signout',
    element: <Signout />
  },
  {
    path: '/mapping',
    element: <Mapping />
  },
  {
    path: '/jobs',
    element: <Job />
  },
  {
    path: '/jobs/desktop',
    element: <JobDesktop />
  },
  {
    path: '/logs',
    element: <Logs />
  },
  {
    path: '/default',
    element: <DefaultConfig />
  },
  {
    path: '/disconnect',
    element: <Disconnect />
  },
  {
    path: '/qbd',
    element: <QBD />
  }
];

export default AppRoutes;
