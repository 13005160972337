import { _request } from '../http'
import * as Account from '../../account';
import * as api from '../api'
const endpoint = "http://localhost:7777"

export async function connect() {
    return _request("GET", `${endpoint}/SyncService/connect`, null)
}

export async function sync(data) {
    return _request("POST", `${endpoint}/SyncService/sync`, data)
}

export async function health() {
    return _request("GET", `${endpoint}/health`, null)
}

export async function checkQB() {
    return _request("GET", `${endpoint}/health/quickbooks`, null)
}

export async function checkConnector() {
    return _request("GET", `${endpoint}/health/connector`, null)
}

export async function checkQBFCWorks() {
    return _request("GET", `${endpoint}/health/qbfc`, null)
}

export async function launchInstallQBFC() {
    return _request("GET", `${endpoint}/health/qbfc/install`, null)
}

export async function company() {
    return _request("GET", `${endpoint}/data/company`, null)
}

export async function customers() {
    return _request("GET", `${endpoint}/data/customers`, null)
}

export async function customerByName(name) {
    return _request("GET", `${endpoint}/data/customers/${name}`, null)
}

export async function addCustomer(data) {
    return _request("POST", `${endpoint}/data/customers`, data)
}

export async function products() {
    return _request("GET", `${endpoint}/data/products`, null)
}

export async function getOrder(id) {
    return _request("GET", `${endpoint}/data/orders/${id}`, null)
}

export async function addOrder(data) {
    return _request("GET", `${endpoint}/data/orders`, data)
}

export async function sampleCustomer(search) {
    return _request("GET", `${endpoint}/data/customers/sample?search=${search ? search : ""}`, null)
}

export async function sampleProduct(search) {
    return _request("GET", `${endpoint}/data/products/sample?search=${search ? search : ""}`, null)
}

export async function sampleOrder(search) {
    return _request("GET", `${endpoint}/data/orders/sample?search=${search ? search : ""}`, null)
}

export async function sampleCustomerCustomField() {
    return _request("GET", `${endpoint}/data/customers/customfields`, null)
}

export async function sampleProductCustomField() {
    return _request("GET", `${endpoint}/data/products/customfields`, null)
}

export async function launchQB() {
    return _request("GET", `${endpoint}/health/launch/qb`, null)
}

export async function launchFora() {
    return _request("GET", `${endpoint}/health/launch/fora`, null)
}