
export default function TestAccount(props) {
    return (
        <div className="min-h-full">
            <form method="post" action="https://www.foraconnector.com/account" className="max-w-lg mx-auto">
                <label for="CompanyID" className="block font-medium">Company ID:</label>
                <input type="text" id="CompanyID" name="CompanyID" value="123456" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="ERPType" className="block font-medium">ERP Type:</label>
                <input type="text" id="ERPType" name="ERPType" value="SAP" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="AppKey" className="block font-medium">App Key:</label>
                <input type="text" id="AppKey" name="AppKey" value="abcde12345" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="APIAccessKey" className="block font-medium">API Access Key:</label>
                <input type="text" id="APIAccessKey" name="APIAccessKey" value="xyz789" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="ConnectEnabled" className="block font-medium">Connect Enabled:</label>
                <input type="checkbox" id="ConnectEnabled" name="ConnectEnabled" checked className="h-5 w-5 text-gray-600" />
                <label for="CompanyLoginName" className="block font-medium">Company Login Name:</label>
                <input type="text" id="CompanyLoginName" name="CompanyLoginName" value="1example" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="CompanyLoginPassword" className="block font-medium">Company Login Password:</label>
                <input type="text" id="CompanyLoginPassword" name="CompanyLoginPassword" value="1password" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="Name" className="block font-medium">Name:</label>
                <input type="text" id="Name" name="Name" value="Acme Corporation" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="AgentEmail" className="block font-medium">Agent Email:</label>
                <input type="email" id="AgentEmail" name="AgentEmail" value="jane.doe@acme.com" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="BusinessType" className="block font-medium text-gray-700">Business Type:</label>
                <input type="text" id="BusinessType" name="BusinessType" value="Manufacturing" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="ContactFirstName" className="block font-medium text-gray-700">Contact First Name:</label>
                <input type="text" id="ContactFirstName" name="ContactFirstName" value="John" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="ContactLastName" className="block font-medium text-gray-700">Contact Last Name:</label>
                <input type="text" id="ContactLastName" name="ContactLastName" value="Doe" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="ContactPhone" className="block font-medium text-gray-700">Contact Phone:</label>
                <input type="tel" id="ContactPhone" name="ContactPhone" value="123-456-7890" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="ContactEmail" className="block font-medium text-gray-700">Contact Email:</label>
                <input type="email" id="ContactEmail" name="ContactEmail" value="john.doe@acme.com" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="Address1" className="block font-medium text-gray-700">Address 1:</label>
                <input type="text" id="Address1" name="Address1" value="123 Main St" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="Address2" className="block font-medium text-gray-700">Address 2:</label>
                <input type="text" id="Address2" name="Address2" value="Suite 100" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="City" className="block font-medium text-gray-700">City:</label>
                <input type="text" id="City" name="City" value="Anytown" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="State" className="block font-medium text-gray-700">State:</label>
                <input type="text" id="State" name="State" value="CA" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="Zip" className="block font-medium text-gray-700">Zip:</label>
                <input type="text" id="Zip" name="Zip" value="12345" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="Country" className="block font-medium text-gray-700">Country:</label>
                <input type="text" id="Country" name="Country" value="USA" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <label for="CompanyLogoURL" className="block font-medium text-gray-700">CompanyLogoURL:</label>
                <input type="text" id="CompanyLogoURL" name="CompanyLogoURL" value="https://example.com/example.jpg" className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-2" />
                <button type="submit" className="px-4 py-2 text-base font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Submit</button>
            </form>
        </div>
    )
} 