import React, { useState, useMemo, useEffect } from "react"
import TopNavbar from "../../shares/TopNavbar"
import * as api from '../../services/api'
import * as Account from '../../account';

export default function Callback(props) {
    const query = new URLSearchParams(window.location.search);

    useEffect(() => {
        var data = {
            code: query.get("code"),
            realmId: query.get("realmId"),
            state: query.get("state")
        }
        api.settingQuickbook(data)
            .then(response => {
                if (response) {
                    window.location.href = "/"
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [query])

    return (<></>)
} 