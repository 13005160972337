import * as qbd from '../../services/qbd'
export default function QBD(props) {

    qbd.customers()
    .then(response => {
       console.log(response)
    })
    .catch(err => {
        console.log(err)
    })

    qbd.products()
    .then(response => {
       console.log(response)
    })
    .catch(err => {
        console.log(err)
    })

    return (
        <div className="min-h-full">
            
        </div>
    )
} 