import {_request,_authorizedRequest} from '../http'

export async function signin(auth) {
    return _request("POST", `/Account/signin`, auth)
}
export async function signup(auth) {
    return _request("POST", `/Account/signup`, auth)
}

export async function connect(auth) {
    return _request("POST", `/Account`, auth)
}

export async function connectQuickBooks() {
    return _request("GET", `/QuickBooks/authorize`, null)
}
