import React, { useState, useMemo, useEffect } from "react"
import TopNavbar from "../../shares/TopNavbar"
import * as Account from '../../account';
import * as api from '../../services/api'
import { sortOnKeys, locale } from "../../utils";
import step2 from '../../images/step2.png';
import step3 from '../../images/step3.png';
import step4 from '../../images/step4.png';
import step5 from '../../images/step5.png';
export default function Disconnect(props) {
    return (
        <div className="min-h-full">
            <TopNavbar />
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8 flex items-center">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900 ml-4">QuickBooks Disconnected</h1>
                </div>
            </header>
            <main className="container mx-auto px-4 w-full items-center justify-center">
                <div className="min-h-screen">
                    <div className="max-w-4xl mx-auto py-4">
                        <div className="space-y-6 w-full">
                            <div className="bg-white shadow-lg rounded-lg px-6 py-4 flex items-center justify-between">
                                <div className="w-full">
                                    <div className="relative m-2 text-center text-xl font-medium">
                                        Your QuickBooks integration has been disconnected.
                                    </div>
                                    <div className="relative m-2 text-md font-medium">
                                        You will no longer to be able to sync data directly to Fora Mobile Commerce Platform from QuickBooks account.
                                    </div>
                                    <br />
                                    <div className="relative m-2">
                                        <p>
                                            If you would like to reconnect Fora Mobile Commerce Platform account with QuickBooks account,
                                        </p>
                                        <p>You can also follow step-by-step below.</p>


                                        <ol class="mt-4 ml-8 relative border-l border-gray-200 ">
                                            <li class="mb-8 ml-8">
                                                <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -left-4 ring-4 ring-white text-blue-500">
                                                    1
                                                </span>
                                                <h3 class="font-medium leading-tight ml-2"> <a href="/">Back to dashboard.</a></h3>
                                            </li>
                                            <li class="mb-16 ml-8">
                                                <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -left-4 ring-4 ring-white text-blue-500">
                                                    2
                                                </span>
                                                <h3 class="font-medium leading-tight ml-2">Select "QuickBooks Online".</h3>
                                                <img className="mt-2" src={step2} width={500} />

                                            </li>
                                            <li class="mb-16 ml-8">
                                                <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -left-4 ring-4 ring-white text-blue-500">
                                                    3
                                                </span>
                                                <h3 class="font-medium leading-tight">Press "Connect to QuickBooks" button.</h3>
                                                <img className="mt-2" src={step3} width={500} />
                                            </li>
                                            <li class="mb-16 ml-8">
                                                <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -left-4 ring-4 ring-white text-blue-500">
                                                    4
                                                </span>
                                                <h3 class="font-medium leading-tight">Enter in your QuickBooks login credentials and then press "Sign in" button.</h3>
                                                <blockquote class="p-2 my-2 border-l-4 border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-400">
                                                    <p class="text-sm italic font-medium leading-relaxed text-gray-100 dark:text-white">Note: If you're already logged into QuickBooks, it will automatically skip this step.</p>
                                                </blockquote>
                                                <img className="mt-2" src={step4} width={400} />
                                            </li>

                                            <li class="ml-8">
                                                <span class="absolute flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -left-4 ring-4 ring-white text-blue-500">
                                                    5
                                                </span>
                                                <h3 class="font-medium leading-tight">Press "Connect" button.</h3>
                                                <img className="mt-2" src={step5} width={400} />
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div >
    )
}